.modal-wrapper {
    z-index: 1000;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
}

.mediumModal {
    min-height: 505px;
    height: auto;
}

@media (max-width: 768px) {
    .littleModal h2 {
        text-align: center !important;
        font-size: 20px;
        width: 100%;
    }
}

.modal {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}
.modal p.info {
    text-align: center;
}

.modal-overlay {
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-close-button {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 7px;
    top: 7px;
    border-radius: 0;
    background-color: #e20118;
    border: 0;
    color: #ffffff;
    font-size: 1.2em;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-close-button:hover, .button-form:hover {
    background-color: #b40013;
}

.modal-header h2, .modal .intro {
    text-align: center;
}
.modal-header h3 {
    text-align: center;
    margin: 0;
}

.modal .intro {
    font-size: 0.9em;
}
.modal .long_intro {
    font-size: 0.7em;
}

.modal .info_temp_mdp {
    margin-bottom: 0;
    text-align: center;
    font-size: 0.9em;
}
.modal .link_expired_mdp {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 0.7em;
    display: block;
}

.changeInfos input {
    width: 90%;
    padding: 10px;
    margin: 5px 0px;
    border: #cccccc solid 1px;
}

.button-form  {
    width: 100%;
    margin-top: 5px;
    background-color: #e20118;
    border: none;
    padding: 15px 0px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom:10px;
    transition: 0.3s;
}

.button-form:disabled {
    opacity: .7;
}