@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-Regular.ttf');
}
body {
  margin: 0;
  font-family: 'Poppins', Arial, Helvetica, sans-serif,FontAwesome;
  height: 100%;
}

h1, h2, h3, h4, p, button, a {
  font-family: 'Poppins', Arial, Helvetica, sans-serif,FontAwesome;
}

a, button {
  cursor: pointer;
  text-decoration: none;
}

a.buttona { color:#fff !important; text-decoration: none; }
a.buttona:hover { text-decoration: none !important;; }

header {
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 4px 5px rgba(197, 197, 197,0.4);
}

header img {
  margin-left: 20px;
  width: 45px;
  height: 45px;
}
header h2 {
  font-weight: 200;
  font-size: 20px ;
}

.error {
  border: red 1px solid !important;
}

