html, body {
    height: 100% ;
    margin: 0;
    padding: 0;
}

header {
    position:fixed;
    top:0;
    z-index:1000;
    width: 100%;
    background-color: white;
}

header h2 {
    color: black;
    margin: 0;
    padding-left: 15px;
    font-style: normal;
}

main {
    position: absolute;
    top:80px;
    left:0;
    right: 0;
    bottom:0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 15%;
    overflow-x :hidden;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    width:4px;
}

::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius:4px;
}

.main-home{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: calc(100% + 30px);
    background-color: rgb(242,242,242);
}

main .intro {
    margin-top: 10px;
    background: white;    /*radial-gradient(circle, rgb(209, 90, 156) 0%, rgb(49, 0, 54));*/
    background-size: cover;
    width: 80%;
    height: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    border-radius: 10px;
    margin:auto;
}

main .intro h3 {
    padding-top: 10px;
}
main .intro .firstName{
    text-transform: capitalize;
}

main .intro a {
    color: black;
    text-decoration: none;
    font-size: 14px;
}

main .intro a:hover {
    color: black;
    text-decoration: underline;
}

main .sections {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 15px 10%;
    width: 80%;
}

main .sections img {
    width: 6.5rem;
    margin-bottom: 5px;
}

main .sections p {
    margin: 0;
    margin-top: 10px;
}

main .sections a {
    width: 45.4%;
    padding: 2%;
    background-color: white;
    margin: 0.3%;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 22px;
    font-weight: bold;
    border-radius: 10px;
    position: relative;
}

main .sections a:nth-of-type(3) {
    background: white /*radial-gradient(circle, rgb(19, 155, 214) 0%, rgb(27, 33, 87) 100%)*/;
    color: black;
}

main .sections a:nth-of-type(3) p:last-of-type {
    font-size: 18px;
}

main .sections a:last-of-type {
    color: black;
    background-image: url(../assets/images/GettyImages-1182291664_RVB.jpg);
    background-repeat: no-repeat;
    text-align: left;
    align-items: flex-start;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    background-size: 132%;
    background-position-x: 90%;
    background-position-y:60%;
}
main .sections a:last-of-type::before {
    content:"";
    position:absolute;
    top:0;
    right:0;
    width: 50%;
    height: 100%;
    background-color: red;
    opacity: 0.8;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

main .sections a:last-of-type::after {
    content: "";
    position: absolute;
    top: 10%;
    left: 0;
    width: 60px;
    height: 60px;
    background-image: url(../assets/images/SFR_Logo_RVB.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 1; /* Réglez l'opacité selon vos besoins */
}

#discover_last_offers {
    position:absolute;
    align-self: center;
    z-index: 1000;
    color:white;
    font-size: 25px;
    letter-spacing: 0.15rem;
}

main .sections a:last-of-type p {
    /* width: 176px; */
    text-align: center;
    font-weight: bold;
    margin: 0;
}


main .sections a:last-of-type img {
    margin-top: 20px;
    width: 90px;
}

@media (max-width: 1210px) {
    main {
        padding: 20px 5%;
    }
    .main-home{
        height: auto;
    }

}

@media  (max-width: 1210px) and (max-height:670px) {
    .main-home{
        width: 100%;
        height:auto;
        padding-bottom:20px;
    }

}

@media (max-width: 940px) {
    .main-home{
        width: 100%;
        height: auto;
        overflow-y: auto;
    }

    main .sections a:last-of-type {
        /*background: black;*/
        color: black;
        background-image: url(../assets/images/GettyImages-1182291664_RVB_481.jpg);
        background-repeat: no-repeat;
        text-align: left;
        align-items: flex-start;
        display: flex;
        font-size: 22px;
        font-weight: bold;
        background-size: 140%;
        background-position-x: 100%;
        background-position-y: 70%;
    }

    main .sections a {
        text-align: center;
        width: 92%;
        padding: 4%;
        margin: 5px 0px;
        height: 250px;
    }
    main .sections a:last-of-type img {
        margin-top: 20px;
        width: 50px;
    }
    main .sections a:last-of-type p {
        width: 100%;
        font-size: 22px;
        font-weight: bold;

    }
    #firstHelpKitImg{
        width: 60px;
        height:60px;
    }
}

@media(max-width : 281px){
    .main-home{
        overflow-y: scroll;
    }

    main .sections a:last-of-type {
        background-image: url(../assets/images/GettyImages-1182291664_RVB_481.jpg);
        background-size: 160%;
        background-position-x: -40%;
        background-position-y: -60%;
    }
    main .sections a:last-of-type::after {
        content: "";
        position: absolute;
        top: 10%;
        left: 0;
        width: 40px;
        height: 40px;
        background-image: url(../assets/images/SFR_Logo_RVB.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 1; /* Réglez l'opacité selon vos besoins */
    }
    #discover_last_offers {
        position:absolute;
        align-self: center;
        z-index: 1000;
        color:white;
        font-size: 15px !important;
        letter-spacing: 0.15rem;
    }
}
.main .sections a:last-of-type {
    color: black;
    background-image: url(../assets/images/GettyImages-1182291664_RVB.jpg), linear-gradient(red, red);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: left;
    align-items: flex-start;
    display: flex;
    font-size: 22px;
    font-weight: bold;
    position: relative;
}

/* Styles pour les écrans larges */

@media (min-width: 768px) {
    main .sections a:last-of-type {
        background-position-x: 100%;
        background-position-y: 60%;
    }
}


/* Styles pour les écrans moyens */
@media(max-width: 767px) {
    main .sections a:last-of-type {
        background-position-x: 100%;
        background-position-y: 60%;
    }
}

/* Styles pour les écrans petits */
@media (max-width: 480px) {
    main .sections a:last-of-type {
        background-position-x: 100%;
        background-position-y: 60%;
    }
}
