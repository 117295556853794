@font-face {
  font-family: 'Poppins';
  src: url('../font/Poppins-Regular.ttf');
}
.App, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial;
}

main {
  position: absolute;
  top:80px;
  left:0;
  right: 0;
  bottom:0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 40px 0px;
  background-size: cover;
}


.phishing-infos {
  text-align: right;
  color: black;
  z-index: 1;
  position: relative;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width:400px;
  max-width: 90%;
}

#cadenas{ 
  width:26px;
  height:26px;
  margin-top:-5px;
}
.phishing-infos .tooltip, .links .tooltip {
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  border-bottom: 1px  black;
}
#email, #password {
  font-family: 'Poppins', Arial;
}

.tooltiptext {
  visibility: hidden;
  background-color: #ffffff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 3;
  padding: 16px;
  border: none;
  border-radius: 4px;
  transition: 0.3s;
  transform-origin: top center;
  font-size: 11px;
  width: 500px;
  position: absolute;
  box-shadow: 1px 1px 10px #808080cf;
}

.links .tooltip .tooltiptext {
  bottom: 20px;
  left:10px;
}

.phishing-infos .tooltip .tooltiptext a, .phishing-infos .tooltip .tooltiptext a:visited, .links .tooltip .tooltiptext p {
  display: block;
  color: #233134;
}

.links .tooltip .tooltiptext p {
  margin: 0;
}

.phishing-infos .tooltip .tooltiptext a span, .phishing-infos .tooltip .tooltiptext a:hover {
  color: #e20118;
}

.phishing-infos .tooltip:hover .tooltiptext, .links .tooltip:hover .tooltiptext {
  visibility: visible;
}

.phishing-infos img {
  vertical-align: middle;
}

.link-id{
  width:108% !important;
}

.login {
  border: 1px solid #d3d3d3;
  width: 400px;
  max-width: 90%;
  position: relative;
  background-color: white;
  border-radius: 5px;
}

.login .message {
  text-align: center;
  color: #e20118;
  font-size: 15px;
  display: none;
}

.login form {
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login h2 {
  margin: 10px 0px;
  font-weight:400;
}

.login label {
  margin: 5px 0px;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.login label span {
  display: inline-block;
  width: 22px;
  height: 22px;
  width: auto\9;
  height: auto\9;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
  vertical-align: top;
  margin-top: 0;
  border: 0!important;
  padding: 0!important;
  background-image: url("../assets/images/sprite-mire-2016.png");
  background-repeat: no-repeat;
  background-position: 0px -700px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  position: relative;
  outline: none;
}

.login input {
  width: calc(100% - 25px);
  font-size:16px;
  padding: 10px;
  margin: 5px 0px;
  border: #cccccc solid 1px;
}
#email::placeholder{
  color:rgb(157,157,157);
}
#password::placeholder{
  color:rgb(157,157,157);
}
.login .label-password {
  margin: 5px 0px;
  margin-top: 20px;
  display: none;
}

.login .label-password-verification {
  margin: 5px 0px;
  margin-top: 20px;
  text-align: center;
}

.login .connect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
  width: 100%;
}
.login #connect{
  width: 25px;
  height:25px;
}
.checkbox-custom-red{
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: "✔";
    display: none;
    color: rgb(226,0,26);
    font-size: 20px;
  }
  &:checked {
    background-color: white;
    &:after {
      display: block;
    }
  }
}

.login .connect input {
  width: auto;
  margin-right: 5px;
}

.login .connect {
  margin: 0px;
}


.link_expired_mdp {
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.login .button-form {
  display: none;
}

.infoError, .infoModalError {
  font-size: 10px;
  color: #e20118;
}

.infoError {
  margin: 10px 0 0 0;
}

.infoModalError {
  margin: 0 0 10px 0;
  text-align: left;
}

.captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform : scale(0.75);
}

.rc-anchor-normal-footer {
	display: inline-block;
	height: 74px;
	vertical-align: top;
}

.rc-anchor-normal .rc-anchor-pt {
	margin: 4px 13px 0 0;
	padding-right: 2px;
	position: absolute;
	right: 0px;
	text-align: right;
	width: 276px;
}

#rc-anchor-alert, .rc-anchor-alert {
  display: none !important;
}

.linkto {
  padding: 20px;
  width: 100%;
  margin-top: 5px;
  background-color: #e20118;
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 15px 0px;
  text-align: center;
  margin-top: 10px;
  transition: 0.3s;
  padding: 0;
  color: white;
  border: 0;
  font-weight: bold;
  font-size: 18px;
  padding: 15px 20px;
}

.linkto:hover {
  background-color: #c00013;
}

.linkto:disabled {
  /* cursor: not-allowed; */
  color: whitesmoke;
  background-color: rgb(226,0,26);
  opacity: .7;
}

.ppal_link {
  margin: 0 5% 5% 5%;
  width: 90%;
  background-color:rgb(226,0,26);
}

.login .help {
  border-top: #d3d3d3 1px solid;
  width:auto;
}

.login .help h3 {
  margin: 6px 0px;
  text-transform: uppercase;
  text-decoration:none;
  justify-content: center;
  color: #b9b9b9;
  text-align: center;
  line-height: 0.9rem;
  letter-spacing: 0.02rem;
  width: 100%;
  display: flex;
}

.login .help .links{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.login .help .links div a {
  font-size: 0.8em;
  color: #ffffff;
  border: none;
}

.login .help div a {
  width: 100%;
  margin: 5px 0px;
  font-size: 15px;
  position: relative;
}

.login .help div span {
  color: #e20118;
  font-weight: 1000;
  font-size: 16px;
  margin: 5px 10px;
}

.login .help div a, .login .help div button,p {
  font-size: 11px
}

.login .help .links .link-password{
border-right: 1px solid #b9b9b9;
}

.login .help .links button {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: black;
  box-shadow: none;
  width: 100% !important;
  padding-bottom: 8px;
  text-align: left;
  transition: 0.3s;
}

.login .help .links button:hover {
  color: #dddddd;
}

.icon-input input::before {
  content: "\f007"; 
  color: red; 
  margin-right: 5px; 
}
#forgotten_password::before {
  display: none;
}

.modal-wrapper {
  overflow: scroll;
  width: 85%;
  max-width: 420px;
  max-height: 90%;
}

@media (max-width: 1200px) {
  .phishing-infos .tooltip .tooltiptext, .links .tooltip .tooltiptext {
    width: auto;
  }
}

@media (max-width: 540px) {
 #rc-anchor-container{
    width: 70% !important;
  }
}

@media (max-width: 500px) {
  .login .links div:first-of-type a:first-of-type:before, .login .links div:first-of-type button:first-of-type:before {
    content: none;
  }
  .login .help div::before {
    content: none !important;
  }
  .login .help h3 {
    margin-bottom: 5px;
  }
  .rc-anchor-normal {
    width: 60%;
  }

}

@media (max-width: 415px) {
  .littleModal h2 {
    text-align: left !important;
    font-size: 20px;
  }
}

@media (max-width: 361px) {
  .rc-anchor-container {
    width: 70% !important;
  }
  .rc-anchor-normal {
    width: 260px;
    margin: 0;
  }
}

@media (max-width: 281px) {
  header p {
    width: 32% !important;
    padding-left: 12px;
    text-align: left !important;
  }
  .rc-anchor-container {
    width: 70% !important;
  }
  .rc-anchor-normal {
    width: 260px;
    margin: 0;
  }
  .bigModal .modal-header h2 {
    font-size: 18px;
    width: 66%;
    text-align: left;
  }
  .littleModal header p {
    font-size: 18px;
    width: 66%;
  }
  .modal-overlay {
    overflow: scroll;
  }
}