.main-contracts {
    position:absolute;
    inset: 0;
    width:100%;
    height:120%;
    background-color: rgb(242,242,242);
}

.main-contracts .contracts-help{
    display: flex;
    justify-content:center;
    align-items: center;
}
.main-contracts .intro {
    margin-top: 10px;
    width: 100%;
    font-size: 20px;
}

.main-contracts .contrats {
    align-items: center;
    justify-content: center;
    margin: 2px 10%;
    width: 80%;
}

.main-contracts .contrats .contrat h4 {
    background: white; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    color: black;
    font-size: 25px;
    font-weight: bold;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 7px;
}

.main-contracts .contrats .contrat .infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-contracts .contrats .contrat p {
    margin: 10px 0px;
    font-size: 16px;
}

.main-contracts .contrats .contrat p:first-of-type {
    font-weight: bold;
    font-size: 18px;
}

.main-contracts .contrats .contrat button {
    background: linear-gradient(90deg, rgb(204, 78, 146) 0%, rgb(226, 0, 26));
    border: none;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 16px;
    display:none;
}

.main-contracts .contrats .contrat .content {
    background-color: white;
    padding: 40px;
    border-radius: 0px 0px 10px 10px;
    padding-top: 20px;
    height: fit-content;
    margin-bottom: 10px;
}

.main-contracts .contrats .contrat .liste {
    height: fit-content;
}


main .contrats .contrat .liste label {
  margin-top:12px;
  display:inline-flex;
  font-weight: 600;
}

.main-contracts .intro {
    margin-top: 10px;
    background: white;
    background-size: cover;
    width: 80%;
    height: 95px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 18px;
    border-radius: 10px;
}

.main-contracts .intro h3 {
    margin-bottom: 0px;
}
.main-contracts .intro h3 > span{
    text-transform: capitalize;
}

.main-contracts .intro a {
    text-decoration: none;
    font-size: 14px;
}


.main-contracts .contrats .contrat .liste label {
    margin: 5px 0px;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    cursor: pointer;
    color: #58164f;
    font-weight: 600;
}

.main-contracts .contrats .contrat .liste label span {
    display: inline-block;
    width: 22px;
    height: 22px;
    width: auto\9;
    height: auto\9;
    margin: 0;
    margin-right: 8px;
    cursor: pointer;
    vertical-align: top;
    margin-top: 0;
    border: 0!important;
    padding: 0!important;
    background-image: url("../assets/images/sprite-mire-2016.png");
    background-repeat: no-repeat;
    background-position: 0px -700px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    position: relative;
    outline: none;
}

.main-contracts .contrats .contrat .liste input {
    width: calc(100% - 25px);
    padding: 10px;
    margin: 5px 0px;
    border: #cccccc solid 1px;
}

.main-contracts .contrats .contrat .liste a {
    color: #c95697;
    text-decoration: none;
}

.main-contracts .contrats .contrat .liste a:hover {
    color: #c95697;
    text-decoration: underline;
}

.main-contracts .contrats .contrat audio {
    margin: 10px;
    width:35%
}


.main-contracts .contrats .searchoff {
    /* display: none; */
    margin-bottom: 80px;
}

.main-contracts .contrats .searchon {
    display: block;
    margin-bottom: 80px;
}

.main-contracts .contrats .contrat .loader {
    width: 300px;
    height: 15px;
    border-radius: 30px;
    margin-top: 10px;
    border: rgb(49, 0, 54) 2px solid;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.separator {
    opacity: 0;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #f8f8f8;
}

audio::-webkit-internal-media-controls-download-button {
    display:none;
}
audio::-webkit-media-controls-enclosure {
    overflow:hidden;
}
audio::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}

@keyframes loader {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.loader-percentage-animation {
    animation: loader 1s ease-in-out forwards;
}

.main-contracts .contrats .contrat .loader-percentage {
    background: linear-gradient(90deg, rgb(204, 78, 146) 0%, rgb(226, 0, 26));
    width: 0%;
    height: 15px;
    border-radius: 30px;
    transition: 200ms;
}

.main-contracts .contracts-help .help {
    padding: 10px 25px;
    color: black;
    border:1px solid transparent;
    border-radius: 15px;
    background: white;
    margin-top: 20px; 
    display: flex;
    align-items: center;
    justify-self: unset;
}

.main-contracts .help img {
    margin-right: 20px;
}

.img-contract {
    width: 75px;
    height: 75px;
}

.main-contracts .help .texts h3 {
    margin: 0;
    margin-top: 15px;
    font-size: 18px;
}

.main-contracts .help .texts p {
    margin: 0px;
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 15px;
}

/**** Mise en page par les differents screens ****/

@media (max-width: 1210px) {
    .main-contracts{
        height:auto;
        padding-bottom:20px;
    }
    
}


@media  (max-width: 1210px) and (max-height:670px) {
    .main-contracts{
        width: 100%;
        height:200%;
        padding-bottom:20px;
    }
}
@media  (max-width: 940px) {

    .main-contracts{
        width: 100%;
        height:auto;
        padding-bottom:20px;
        overflow-y: auto;
    }
}

@media (max-width: 540px) {
    .main-contracts{
        padding-bottom:10px;
        overflow-y: auto;
    }
    .main-contracts .contracts-help{
        margin-bottom: 10px;
    }

    .main-contracts .contracts-help .help {
        padding: 5px 5px;
        width:70%;
        height:80px;
        color: black;
        border:1px solid transparent;
        border-radius: 15px;
        background: white;
        margin-top: 20px; 
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-self: unset;
    }
    .img-contract{
        width: 50px;
        height:50px;

    }
    .main-contracts .contrats .contrat .infos {
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
    main .contrats .contrat .content {
        padding: 20px;
        flex-direction: column;
        align-items: center;
    }
    main .contrats .contrat .loader {
        margin: 15px auto 10px auto;
        width: 250px;
    }
    main .contrats .contrat audio {
        margin: 15px auto 10px auto;
        width: 80%;
    }
    main .contrats .contrat button {
        margin: 10px 0px;
    }
}



@media(max-width:281px){
    header h2 {
        color: black;
        margin: 0;
        padding-left: 15px;
        font-style: normal;
        font-size: 15px;
    }

    .main-contracts{
        overflow-y: scroll;
    }

    .main-contracts .intro {
        margin-top: 10px;
        background: white;
        background-size: cover;
        width: 80%;
        height: 95px;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: black;
        font-size: 15px;
        border-radius: 10px;
    }
    
    .main-contracts .intro h3 {
        margin-bottom: 0px;
        text-transform: capitalize;
    }
    
    .main-contracts .intro a {
        text-decoration: none;
        font-size: 12px;
    }
    
    .help{
        width: 60%;
    }
    .img-contract{
        width:60px;
        height: 60px;
    }
    main .help .texts h3 {
        font-size: 15px;
    }
    main .help .texts p {
        font-size: 13px;
    }
    main .contrats .contrat p:first-of-type{
        font-size:15px;
    }
    main .contrats .contrat audio {
        margin: 15px auto 10px auto;
        width: 80%;
    }
    .main-contracts .contracts-help .help {
        padding: 5px 5px;
        color: black;
        border:1px solid transparent;
        border-radius: 15px;
        background: white;
        margin-top: 20px; 
        display: flex;
        align-items: center;
        justify-self: unset;
    }
    .img-contract{
        width:40px;
        height: 40px;
    }
    .main-contracts .help .texts h3 {
        font-size: 12px;
    }
    .main-contracts .help .texts p {
        font-size: 10px;
    }
}


/**** Mise en page par les differents screens ****/

/* Styles pour les écrans moyens */
